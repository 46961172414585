<template>
  <div class="table-responsive adaptive-table" v-if="list.length > 0">
    <table class="table table-striped table-hover adaptive-table" style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in dataList" :key="row.id" @click="goToInvoice(row.id)">
          <td :data-label="$t('forms.billing.rows.number')">{{ row.invoice_no }}</td>
          <td :data-label="$t('forms.billing.rows.amount')">{{ row.localized_total_amount }}</td>
          <td :data-label="$t('forms.billing.rows.dateCreate')">{{ humanDate(row.date, $i18n.locale, { year: 'numeric', month: 'short', day: 'numeric' }) }}</td>
          <td :data-label="$t('forms.billing.rows.status')">
            {{ $t(`forms.billing.payment_statuses.${row.status}`) }}
            <template v-if="row.status === 'unpaid' && row.due_date">
              <br>
              <small class="text-danger">
                {{ $t('forms.billing.rows.dateDue') }}
                {{ humanDate(row.due_date, $i18n.locale, { year: 'numeric', month: 'short', day: 'numeric' }) }}
              </small>
            </template>
            <template v-else-if="row.status === 'paid'">
              <hint title="forms.billing.receipt">
                <a class="feather icon-file-text receipt-link"
                   target="_blank"
                   @click.stop
                   :href="row.payment.receipt"></a>
              </hint>
              <template v-if="row.paid_date">
                <br>
                <small class="text-success">
                  {{ $t('forms.billing.rows.datePaid') }}
                  {{ humanDate(row.paid_date, $i18n.locale, { year: 'numeric', month: 'short', day: 'numeric' }) }}
                </small>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useHumanDate } from '@/application/composables/humanDate.js'
import { defineAsyncComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'ScheduledCampaignsTable',
  components: {
    hint: defineAsyncComponent(() => import('@/application/components/elements/Hint')),
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { humanDate } = useHumanDate()
    const router = useRouter()
    const dataList = ref(props.list)
    const fields = ['forms.billing.rows.number', 'forms.billing.rows.amount', 'forms.billing.rows.dateCreate', 'forms.billing.rows.status']

    onMounted(() => {
      window.$(function() {
        window.$('[data-toggle="tooltip"]').tooltip()
      })
    })

    return {
      humanDate,
      fields,
      dataList,
      goToInvoice: (id) => router.push({ name: 'invoice', params: { id: id } }),
      goToLink: (link) => window.open(link, '_blank').focus(),
    }
  },
}
</script>
